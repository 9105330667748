<template>
  <NuxtLink
    :is="buttonOrNuxtLink"
    v-bind="to ? { to: to } : {}"
    :target="$useTarget(to, target)"
    class="group flex items-center gap-3 text-base font-medium text-blue-800 transition-colors hover:text-blue-dark"
  >
    <slot name="icon" />

    <slot />

    <ExternalLinkIcon
      v-if="showLinkArrow && $isExternalUrl(to)"
      class="mb-1 w-5"
    />
    <ArrowGrowIcon v-if="showLinkArrow && !$isExternalUrl(to)" class="w-5" />
  </NuxtLink>
</template>

<script setup>
const props = defineProps({
  to: { type: [String, Object], default: null },
  showLinkArrow: { type: Boolean, default: false },
  target: { type: String, default: null },
})

// determine whether to load NuxtLink or button
const buttonOrNuxtLink = computed(() => {
  if (props.to) {
    return resolveComponent('NuxtLink')
  }

  return 'button'
})
</script>
